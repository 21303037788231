import React from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'
import BottomBar from '../components/BottomBar'

import ContactInfo from '../components/contact/ContactInfo'

const ContactPage = ({ data }) => {
  const page = data.wpPage
  return (
    <>
      <SEO path="/contact" title={he.decode(page.seo.title)} description={he.decode(page.seo.metaDesc)} />
      <Header isDark />
      <ContactInfo content={page.content} />
      <Footer isCompact />
      <BottomBar />
    </>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(databaseId:{eq:387}) {
      seo {
        title
        metaDesc
      }
      content
    }
  }
`

export default ContactPage
